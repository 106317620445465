import { DiscountedCashFlowResponseData, FormData } from './types'

// Function to handle the discounted cash flow request
export const handleDiscountedCashFlowResponse = async (
  formData: FormData,
  setDiscountedCashFlowResponseData: React.Dispatch<React.SetStateAction<DiscountedCashFlowResponseData | null>>,
  setError: React.Dispatch<React.SetStateAction<{
    message: string;
    id: number
  } | null>>,
  fetchWithAuth: (endpoint: string, data: any) => Promise<Response>
) => {
  try {
    const response = await fetchWithAuth("/intrinsic_value/", formData)
    if (response.ok) {
      const intrinsicData: DiscountedCashFlowResponseData = await response.json()
      setDiscountedCashFlowResponseData(intrinsicData)
    } else {
      const errorData = await response.json()
      const errorMessage = `Request failed with error ${response.status}: ${errorData['detail']}`
      setError({ message: errorMessage, id: Date.now() })
      setTimeout(() => setError(null), 5000)
    }
  } catch (error) {
    const errorMessage = `An error occurred: ${(error as Error).message}`
    setError({ message: errorMessage, id: Date.now() })
    setTimeout(() => setError(null), 5000)
  }
}