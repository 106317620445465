import { useMsal } from "@azure/msal-react";
import { protectedResources } from './authConfig'

const useAuthenticatedFetch = () => {
  const { instance, accounts } = useMsal();

  const fetchWithAuth = async (endpoint: string, data: any) => {
    // Define your token request including the required scopes.
    const tokenRequest = {
      scopes: protectedResources.backendApi.scopes,
      account: accounts[0],
    };

    // Acquire the token silently.
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
    const accessToken = tokenResponse.accessToken;

    // Call the API with the Bearer token.
    return await fetch(protectedResources.backendApi.endpoint + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });
  };

  return { fetchWithAuth };
};

export default useAuthenticatedFetch;
