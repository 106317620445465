export const roundingConfig: { [key: string]: number } = {
  market_cap: 0,
  intrinsic_value: 0,
  average_annual_growth_rate: 3,
  initial_free_cash_flow: 0,
  average_market_cap_to_free_cash_flow_ratio: 3,
  liquid_assets_value: 0,
  debt_total: 0,
  market_cap_current: 0,
  market_cap_to_intrinsic_value_ratio: 3,
  highest_acceptable_buy_price: 2,
}