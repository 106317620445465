import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react'
import { Button } from '@mui/material'
import './App.css'
import OutputView from './OutputView';


function App () {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated()

  return (
    <div className="App">
      <header className="App-header">
        <h1>Finance Calculator</h1>
      </header>
      {
        isAuthenticated && (
          <Router>
            <Routes>
              <Route path="/" element={<OutputView />} />
            </Routes>
          </Router>
        )
      }
      {
        !isAuthenticated && (
            <div className="login-button-view">
                <Button onClick={() => instance.loginPopup()} variant="contained" size="large">Login</Button>
            </div>
        )
      }
    </div>
  )
}

export default App
