import { API_URL, CLIENT_ID, REDIRECT_URI, TENANT_NAME } from './config'

export const b2cPolicies = {
     names: {
         signUpSignIn: "B2C_1_susi",
         editProfile: "b2c_1_edit"
     },
     authorities: {
         signUpSignIn: {
             authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_susi`,
         },
         editProfile: {
             authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_edit`
         }
     },
     authorityDomain: `${TENANT_NAME}.b2clogin.com`
 };

export const msalConfig = {
     auth: {
         clientId: CLIENT_ID,
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: REDIRECT_URI,
     },
    // More configuration here
 }

export const protectedResources = {
  backendApi: {
    endpoint: API_URL,
    scopes: [`https://${TENANT_NAME}.onmicrosoft.com/invcalc-backend/invcalc.read`],
  },
}