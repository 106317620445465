import React, { useEffect, useRef, useState } from 'react'
import {
  FormData,
  DiscountedCashFlowResponseData,
  FinancialsGrowthResponseData,
} from './types'
import { defaultInputValues } from './defaults'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getNewFormData,
  getUpdatedURL,
  submitFormData,
  wakeupCall,
} from './utils'
import Spinner from './Spinner'
import IntrinsicValueOutputTable from './IntrinsicValueOutputTable'
import FinancialsGrowthTable from './FinancialsGrowthOutputTable'
import './IntrinsicValueView.css'
import useAuthenticatedFetch from './requestAuthenticated'


export const OutputView: React.FC = () => {
  const [formData, setFormData] = useState<FormData>(defaultInputValues)
  const [discountedCashFlowResponseData, setDiscountedCashFlowResponseData] = useState<DiscountedCashFlowResponseData | null>(null)
  const [financialsGrowthResponseData, setFinancialsGrowthResponseData] = useState<FinancialsGrowthResponseData | null>(null)
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<{ message: string; id: number } | null>(null);
  const didEffectRun = useRef(false);
  const isSubmitted = useRef(false)
  const [symbolsInput, setSymbolsInput] = useState<string>(defaultInputValues.symbols.join(", ")); // Temporary input state for symbols

  const location = useLocation();
  const navigate = useNavigate();

  const { fetchWithAuth } = useAuthenticatedFetch()

  useEffect(() => {
    if (didEffectRun.current) return;
    didEffectRun.current = true;

    wakeupCall().then().finally()

    const params = new URLSearchParams(location.search);
    const newFormData = getNewFormData(params)

    setFormData(newFormData);
    setSymbolsInput(newFormData.symbols.join(", ")); // Initialize symbols input state

  }, [location.search]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value ? parseFloat(value) : 0,
    };
    setFormData(updatedFormData);
    const newParams = getUpdatedURL(updatedFormData);
    navigate({ search: newParams.toString() });
  }

  const clearData = () => {
    setDiscountedCashFlowResponseData(null);
    setFinancialsGrowthResponseData(null);
  }

  const handleReset = () => {
    // Reset form data to the default input values
    setFormData(defaultInputValues);

    // Reset the symbols input field
    setSymbolsInput(defaultInputValues.symbols.join(", "));

    // Navigate to the base URL without any search parameters
    navigate({ search: '' });

    // Clear the response data and reset the submission state
    clearData();

    isSubmitted.current = false;
    setError(null); // Clear any existing errors
  };

  const handleSymbolsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSymbolsInput(e.target.value);
  }

  const handleKeyDownEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSymbolsBlur(() => {
        setTimeout(() => {
          document.getElementById('submit-button')?.click(); // Programmatically click the submit button after blur
        }, 0); // Ensure the state update is completed before triggering the submit
      });
    }
  };

  const handleSymbolsBlur = (callback?: () => void) => {
    const symbolsArray = symbolsInput.split(',').map(symbol => symbol.trim()).filter(symbol => symbol);
    const updatedFormData = {
      ...formData,
      symbols: symbolsArray,
    };
    setFormData(updatedFormData);  // Update formData with new symbols array
    const newParams = getUpdatedURL(updatedFormData);  // Generate new URL parameters with updated formData
    navigate({ search: newParams.toString() });

    if (symbolsArray.length === 0) {
      clearData();
      isSubmitted.current = false; // Reset isSubmitted flag
    }

    if (callback) {
      callback();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (formData.symbols.length === 0) {
      return
    }
    isSubmitted.current = true; // Mark as isSubmitted
    clearData();
    setLoading(true); // Set loading to true when form is isSubmitted
    await submitFormData(formData, setDiscountedCashFlowResponseData, setFinancialsGrowthResponseData, setError, fetchWithAuth);
    setLoading(false); // Set loading to false when response is received
  }

  return (
    <div className="container">
      <div className="input-form">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="symbols">Symbols (comma separated):</label>
            <input
              type="text"
              id="symbols"
              name="symbols"
              value={symbolsInput}
              onChange={handleSymbolsChange}
              onBlur={() => handleSymbolsBlur()} // Update symbols array state on blur
              onKeyDown={handleKeyDownEnter} // Update symbols array state on Enter key press
            />
          </div>
          <div className="form-group">
            <label htmlFor="annualCashFlowDiscount">Annual Cash Flow
              Discount:</label>
            <input
              type="number"
              step="0.01"
              min="0"
              max="1"
              id="annualCashFlowDiscount"
              name="annual_cash_flow_discount"
              value={formData.annual_cash_flow_discount}
              onChange={handleChange}
              readOnly={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="marginOfSafety">Margin of Safety:</label>
            <input
              type="number"
              step="0.01"
              min="0"
              max="1"
              id="marginOfSafety"
              name="margin_of_safety"
              value={formData.margin_of_safety}
              onChange={handleChange}
              readOnly={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="numberOfYears">Number of Years:</label>
            <input
              type="number"
              min="1"
              id="numberOfYears"
              name="number_of_years"
              value={formData.number_of_years}
              onChange={handleChange}
              readOnly={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="maximalAverageGrowthRate">Maximal Average Growth
              Rate:</label>
            <input
              type="number"
              step="0.01"
              min="0"
              id="maximalAverageGrowthRate"
              name="maximal_average_growth_rate"
              value={formData.maximal_average_growth_rate ?? ''}
              onChange={handleChange}
              readOnly={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="maximalMarketCapToFreeCashFlowRatio">Maximal Market
              Cap To Free Cash Flow Ratio:</label>
            <input
              type="number"
              step="0.01"
              min="0.01"
              id="maximalMarketCapToFreeCashFlowRatio"
              name="maximal_market_cap_to_free_cash_flow_ratio"
              value={formData.maximal_market_cap_to_free_cash_flow_ratio ?? ''}
              onChange={handleChange}
              readOnly={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="averageGrowthRateAnnual">Average Growth Rate
              Annual:</label>
            <input
              type="number"
              step="0.01"
              min="0.01"
              id="averageGrowthRateAnnual"
              name="average_growth_rate_annual"
              value={formData.average_growth_rate_annual ?? ''}
              onChange={handleChange}
              readOnly={false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="averageMarketCapToFreeCashFlowRatio">Average Market
              Cap To Free Cash Flow Ratio:</label>
            <input
              id="averageMarketCapToFreeCashFlowRatio"
              name="average_market_cap_to_free_cash_flow_ratio"
              value={formData.average_market_cap_to_free_cash_flow_ratio ?? ''}
              onChange={handleChange}
            />
          </div>
          <div className="button-group">
            <button type="button" onClick={handleReset} className="reset-button">
              <i className="fas fa-undo"></i> {/* FontAwesome Undo Icon */}
            </button>
            <button type="submit" id="submit-button" className="submit-button" disabled={loading || !symbolsInput.trim() /* Disable if loading or symbolsInput is empty/whitespace */}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
        {error && <div className="error">{error.message}</div>} {/* Error message */}
      </div>
      <div className="response-container">
        {loading && !discountedCashFlowResponseData && !financialsGrowthResponseData ? (
          // 1. Display "Spinner" if neither discountedCashFlowResponseData nor financialsGrowthResponseData is set
          <Spinner />
        ) : (
          <>
            {discountedCashFlowResponseData && (
              // Display the IntrinsicValueOutputTable if discountedCashFlowResponseData is available
              <div className="table-wrapper">
                <IntrinsicValueOutputTable
                  responseData={discountedCashFlowResponseData}/>
              </div>
            )}

            {!discountedCashFlowResponseData && loading && (
              <Spinner/>
            )}

            {!financialsGrowthResponseData && loading && (
              // 2. Display the Spinner if only discountedCashFlowResponseData is available but not financialsGrowthResponseData
              <Spinner />
            )}

            {financialsGrowthResponseData && (
              // 3. Display FinancialsGrowthTable if financialsGrowthResponseData is available
              <div className="table-wrapper">
                <FinancialsGrowthTable data={financialsGrowthResponseData}/>
              </div>
            )}
          </>
        )}

      </div>
    </div>
  )
}

export default OutputView
