const outputValueMapping: { [key: string]: string } = {
  company_name: 'Company Name',
  symbol: 'Symbol',
  market_cap: 'Market Cap',
  intrinsic_value: 'Intrinsic Value',
  average_annual_growth_rate: 'Average Annual Growth Rate',
  initial_free_cash_flow: 'Initial Free Cash Flow',
  average_market_cap_to_free_cash_flow_ratio: 'Average Market Cap To Free Cash Flow Ratio',
  liquid_assets_value: 'Liquid Assets Value',
  debt_total: 'Total Debt',
  market_cap_current: 'Current Market Cap',
  market_cap_to_intrinsic_value_ratio: 'Current Market Cap / Intrinsic Value',
  highest_acceptable_buy_price: 'Highest Acceptable Buy Price',
}

export const formatOutputValues = (key: string): string => {
  return outputValueMapping[key] || key
}