import {
  CompanyInformation,
  CompanyValuationResult,
  DiscountedCashFlowResponseData, DiscountedCashFlowResult,
} from './types'
import { formatOutputValues } from './outputValueConfig'
import React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { companyInfoKeys, dcfKeys } from './constants'
import { roundingConfig } from './displaySettings'
import { tableColors } from './colors'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px',
  border: '1px solid #ddd',
  textAlign: 'left',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  [theme.breakpoints.down('sm')]: {
    padding: '8px',
  },
}));

const StickyTableCell = styled(StyledTableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  backgroundColor: tableColors.describingCellBackground,
  zIndex: 2,
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
}));

const StickyHeaderCell = styled(StickyTableCell)(({ theme }) => ({
  zIndex: 3,
  fontWeight: 'bold',
  backgroundColor: tableColors.headerCellBackground
}));


type IntrinsicValueOutputTableProps = {
  responseData: DiscountedCashFlowResponseData;
};

const IntrinsicValueOutputTable: React.FC<IntrinsicValueOutputTableProps> = ({ responseData }) => {
  if (!responseData) return null;

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: '90vh', overflowX: 'auto' }}
    >
      <Table
        stickyHeader
        aria-label="Intrinsic Value Output Table"
        size="small"
        style={{ width: '100%', tableLayout: 'auto', borderCollapse: 'collapse' }}
      >
        <TableHead>
          <TableRow>
            <StickyHeaderCell>
              Metric
            </StickyHeaderCell>
            {responseData.intrinsic_values.map(
              (intrinsic_value_result: CompanyValuationResult, index: number) => (
                <StyledTableCell
                  key={index}
                  style={{ fontWeight: 'bold', backgroundColor: tableColors.describingCellBackground }}
                >
                  {intrinsic_value_result.company_information.company_name}
                </StyledTableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Display Company Information */}
          {companyInfoKeys.map((field: keyof CompanyInformation) => (
            <TableRow key={field}>
              <StickyTableCell>
                {formatOutputValues(field)}
              </StickyTableCell>
              {responseData.intrinsic_values.map(
                (iv: CompanyValuationResult, index: number) => (
                  <StyledTableCell key={index}>
                    {
                      typeof iv.company_information?.[field] === "number"
                        ? iv.company_information[field].toLocaleString(undefined, {
                            maximumFractionDigits: roundingConfig["market_cap"],
                          })
                        : iv.company_information?.[field]
                    }
                  </StyledTableCell>
                )
              )}
            </TableRow>
          ))}
          {/* Display Discounted Cash Flow Result */}
          {dcfKeys.map((field: keyof DiscountedCashFlowResult) => (
            <TableRow key={field}>
              <StickyTableCell>
                {formatOutputValues(field)}
              </StickyTableCell>
              {responseData.intrinsic_values.map(
                (iv: CompanyValuationResult, index: number) => (
                  <StyledTableCell key={index}>
                    {iv.discounted_cash_flow[field].toLocaleString(undefined, {maximumFractionDigits:roundingConfig[field]})}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          ))}
          {/* Display Market Cap to Intrinsic Value Ratio */}
          <TableRow>
            <StickyTableCell>
              {formatOutputValues('market_cap_to_intrinsic_value_ratio')}
            </StickyTableCell>
            {responseData.intrinsic_values.map(
              (iv: CompanyValuationResult, index: number) => (
                <StyledTableCell key={index}>
                  {iv.market_cap_to_intrinsic_value_ratio.toLocaleString(undefined,
                      {maximumFractionDigits:roundingConfig['market_cap_to_intrinsic_value_ratio']})}
                </StyledTableCell>
              )
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};



export default IntrinsicValueOutputTable;