import {
  FinancialsGrowthRequestBody,
  FinancialsGrowthResponseData,
} from './types'

// Sort Growth data in reverse
function sortFinancialsGrowthData (responseData: FinancialsGrowthResponseData) {
  // Map through each company in financials_growths and sort their financials_growth array
  return {
    ...responseData, // Shallow copy of the responseData object
    financials_growths: responseData.financials_growths.map((company) => ({
      ...company, // Shallow copy of each FinancialsGrowth object
      financials_growth: company.financials_growth.sort(
        (a, b) => b.period - a.period, // Sorting by the "period" field in ascending order
      ),
    })),
  }
}

// Function to handle the financials growth request
export const handleFinancialsGrowthResponse = async (
  financialsGrowthInput: FinancialsGrowthRequestBody,
  setFinancialsGrowthResponseData: React.Dispatch<React.SetStateAction<FinancialsGrowthResponseData | null>>,
  setError: React.Dispatch<React.SetStateAction<{
    message: string;
    id: number
  } | null>>,
  fetchWithAuth: (endpoint: string, data: any) => Promise<Response>
) => {
  try {
    const response = await fetchWithAuth("/growth_financials/", financialsGrowthInput)
    if (response.ok) {
      const financialsData: FinancialsGrowthResponseData = await response.json()
      setFinancialsGrowthResponseData(sortFinancialsGrowthData(financialsData))
    } else {
      const errorData = await response.json()
      const errorMessage = `Request failed with error ${response.status}: ${errorData['detail']}`
      setError({ message: errorMessage, id: Date.now() })
      setTimeout(() => setError(null), 5000)
    }
  } catch (error) {
    const errorMessage = `An error occurred: ${(error as Error).message}`
    setError({ message: errorMessage, id: Date.now() })
    setTimeout(() => setError(null), 5000)
  }
}